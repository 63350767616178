@charset "utf-8";

// Define Dolly's branded colors.
$dolly-orange: #e7412a;
$dolly-dark-grey: #232120;
$saffron: #f0c12a;
$blue-chill: #0ea7b5;
$limeade: #8da600;

// Update Bulma's global variables
$primary: $dolly-orange;
$dark: $dolly-dark-grey;
$warning: $saffron;
$success: $limeade;
$info: $blue-chill;

$family-sans-serif: 'Avenir', sans-serif;
$widescreen-enabled: false;
$fullhd-enabled: false;

// Update some of Bulma's component variables
$panel-heading-background-color: whitesmoke;

// Import everything from Bulma.  It's really difficult to debug why a component isn't working
// just because it's not included here.  'utilities' needs to be first.
@import '../node_modules/bulma/sass/utilities/_all.sass';

@import '../node_modules/bulma/sass/base/_all.sass';
@import '../node_modules/bulma/sass/elements/_all.sass';
@import '../node_modules/bulma/sass/grid/_all.sass';
@import '../node_modules/bulma/sass/layout/_all.sass';
@import '../node_modules/bulma/sass/components/_all.sass';
@import '../node_modules/bulma/sass/form/_all.sass';
@import '../node_modules/bulma/sass/helpers/_all.sass';
