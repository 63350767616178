.containerTop {
  padding: 1em;
}

.containerHoriz {
  display: flex; 
  flex-direction: row;
  align-content: flex-start;
}

.fulfillmentJobMissingHelpersModalContent {
  background: white;
  height: 90%;
  min-width: 90%;
  padding: 1em;
}
